@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Cascadia Code';
    src: local('Cascadia Code'), url('./fonts/CascadiaCode.ttf') format('truetype');
}

iframe#webpack-dev-server-client-overlay {
    display: none !important
}

.bp5-tree-node.bp5-tree-node-selected>.bp5-tree-node-content {
    background-color: #03395E !important;
}